<template>
    <div class="search_cont">
        <div class="course_cont">
            <div class="tags_cont">
                <div class="tags">共 {{ total }} 条<span class="key_text" :class="keyword ? 'isMargin' : ''">
                        {{ keyword }} </span>相关的结果</div>
            </div>
            <div class="result">
                <div class="share" v-for="(el, inx) in shareList" :key="inx" :keyword="keyword">
                    <div class="title" v-if="el.type == 2">共享课程</div>
                    <div class="title" v-if="el.type == 1">虚拟仿真课</div>
                    <div class="title" v-if="el.type == 3">直播</div>
                    <div class="container" :class="el.count > 5 ? 'moreShareHeight' : ''">
                        <template v-if="el.type == 1">
                            <div class="item" v-for="(item, index) in vrCourse" :key="index" @click="todetail(el, item)">
                                <div class="item_img">
                                    <el-image :src="item.cover" />
                                </div>
                                <div class="item_detail">
                                    <div class="item_info">
                                        <div class="name">{{ item.name }}</div>
                                        <div class="detail info">
                                            <span>{{ item.school_name }}</span>
                                        </div>
                                        <div class="detail brief">{{ item.brief }}</div>
                                        <div class="detail brief" v-if="el.type == 1">累计访问量：{{ item.count }}</div>
                                        <div class="detail brief" v-if="el.type == 2">加入人数：{{ item.count }}</div>
                                        <div class="detail brief" v-if="el.type == 3">在线人数：{{ item.count }}</div>
                                    </div>
                                </div>
                            </div>
                            <div :class="vrclassName" @click="showShareMore(el)" v-if="el.count > 5">查看更多<i class="el-icon-arrow-down"></i>
                            </div>
                        </template>
                        <template v-else-if="el.type == 2">
                            <div class="item" v-for="(item, index) in shareCourse" :key="index" @click="todetail(el, item)">
                                <div class="item_img">
                                    <el-image :src="item.cover" />
                                </div>
                                <div class="item_detail">
                                    <div class="item_info">
                                        <div class="name">{{ item.name }}</div>
                                        <div class="detail info">
                                            <span>{{ item.school_name }}</span>
                                        </div>
                                        <div class="detail brief">{{ item.brief }}</div>
                                        <div class="detail brief" v-if="el.type == 1">累计访问量：{{ item.count }}</div>
                                        <div class="detail brief" v-if="el.type == 2">加入人数：{{ item.count }}</div>
                                        <div class="detail brief" v-if="el.type == 3">在线人数：{{ item.count }}</div>
                                    </div>
                                </div>
                            </div>
                            <div :class="shareclassName" @click="showShareMore(el)" v-if="el.count > 5">查看更多<i class="el-icon-arrow-down"></i>
                            </div>
                        </template>
                        <template v-else-if="el.type == 3">
                            <div class="item" v-for="(item, index) in liveCourse" :key="index" @click="todetail(el, item)">
                                <div class="item_img">
                                    <el-image :src="item.cover" />
                                </div>
                                <div class="item_detail">
                                    <div class="item_info">
                                        <div class="name">{{ item.name }}</div>
                                        <div class="detail info">
                                            <span>{{ item.school_name }}</span>
                                        </div>
                                        <div class="detail brief">{{ item.brief }}</div>
                                        <div class="detail brief" v-if="el.type == 1">累计访问量：{{ item.count }}</div>
                                        <div class="detail brief" v-if="el.type == 2">加入人数：{{ item.count }}</div>
                                        <div class="detail brief" v-if="el.type == 3">在线人数：{{ item.count }}</div>
                                    </div>
                                </div>
                            </div>
                            <div :class="liveclassName" @click="showShareMore(el)" v-if="el.count > 5">查看更多<i class="el-icon-arrow-down"></i>
                            </div>
                        </template>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import dayjs from "dayjs";
import { getSeachList } from "@/api/course";
export default {
    name: "course",
    components: {},
    // props: ["keyword"],
    data() {
        return {
            // keyStr: '课程',
            // type :'',
            shareList: [],
            vrCourse: [], //虚拟仿真课
            shareCourse: [],//共享课
            liveCourse: [],//直播课
            queryParams: {
                keyword: "",
                college_id: "",
                subject_config_id: "",
                course_type_config_id: "",
                sort_type: 1,
                per_page: 5,
                page: 1,
                type: ''
            },
            totalCount: '',
            current_page: '',
            last_page: '',
            vrclassName: '', //虚拟仿真查看更多
            shareclassName: '', //课程查看更多
            liveclassName: '', //直播查看更多
            shareMore: true,
            privateMore: true,
            vrPage: 1,
            sharePage: 1,
            livePage: 1
        };
    },
    filters: {
        dataformat(value) {
            if (!value) return "";
            return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
        },
    },
    computed: {
        total() {
            if (this.totalCount == '') {
                return 0;
            } else {
                return this.totalCount;
            }
        },
        keyword() {
            return this.$store.state.keyword;
        }
    },
    watch: {
        keyword: {
            handler(val) {
                this.shareMore = true
                this.privateMore = true
                this.queryParams.keyword = val
                this.getCourseListShare();
            },
            immediate: true,
            deep: true,
        },
        shareList: {
            handler(val) {
                this.$forceUpdate()
            },
            immediate: true,
            deep: true,
        }
    },
    created() {
    },
    mounted() {
        window.addEventListener('load', e => this.updateHandler(e))
    },
    destroyed() {
        window.removeEventListener('load', e => this.updateHandler(e))
    },
    beforeDestroy() {
        this.$store.commit("SET_Keyword", '');
        this.getCourseListShare()
    },
    methods: {
        updateHandler(e) {
            this.$store.commit("SET_Keyword", '');
        },
        getCourseListShare() { //共享课程
            this.shareList = []
            this.queryParams.is_in_school = 0
            this.totalCount = ''
            this.queryParams.page = 1
            this.vrPage = 1
            this.sharePage = 1
            this.livePage = 1
            this.queryParams.type = ''
            this.vrclassName = ''
            this.shareclassName = ''
            this.liveclassName = ''
            this.vrCourse = []
            this.shareCourse = []
            this.liveCourse  = []
            getSeachList(this.queryParams)
                .then((res) => {
                    if (res.code == 0) {
                        this.shareList = res.data.data
                        res.data.data.forEach(item => {
                            item.list.forEach(el =>{
                                if (item.type == 1) {
                                    this.vrCourse.push(el)
                                } else if (item.type == 2) {
                                    this.shareCourse.push(el)
                                } else if (item.type == 3) {
                                    this.liveCourse.push(el)
                                }
                            })
                        })
                        this.vrclassName = 'more_cont'
                        this.shareclassName = 'more_cont'
                        this.liveclassName = 'more_cont'
                        if (res.data.total > 0) {
                            this.totalCount = res.data.total
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        showShareMore(el) {
            this.shareMore = false
            if (el.type == 1){
                this.vrPage +=1
                this.queryParams.page = this.vrPage;
            }else if(el.type == 2){
                this.sharePage += 1
                this.queryParams.page = this.sharePage;
            } else if (el.type == 3){
                this.livePage +=1
                 this.queryParams.page = this.livePage;
            }
            this.queryParams.type = el.type
            getSeachList(this.queryParams)
                .then((res) => {
                    if (res.code == 0) {
                        let resss = res.data?.data[0]?.list
                        if (el.type === 1) {
                            if (res.data.current_page == res.data.last_page) {
                                this.vrclassName = 'more_cont more_cont1'
                            } else {
                                this.vrclassName = 'more_cont'
                            }
                            resss.forEach(item => {
                                this.vrCourse.push(item)
                            });
                        } else if (el.type === 2) {
                            if (res.data.current_page == res.data.last_page) {
                                this.shareclassName = 'more_cont more_cont1'
                            } else {
                                this.shareclassName = 'more_cont'
                            }
                            resss.forEach(item => {
                                this.shareCourse.push(item)
                            });
                        } else if (el.type === 3) {
                            if (res.data.current_page == res.data.last_page) {
                                this.liveclassName = 'more_cont more_cont1'
                            } else {
                                this.liveclassName = 'more_cont'
                            }
                            resss.forEach(item => {
                                this.liveCourse.push(item)
                            });
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        todetail(el, item) {
            if (el.type == 1) {
                window.localStorage.setItem("menuId", 1);
                this.$store.dispatch("setmenuid", 1);
                this.$router.push({
                    path: "/home/virtualsimulationexperiment/experimentdetails",
                    query: {
                        id: item.id,
                    },
                });
            } else if (el.type == 2) {
                window.localStorage.setItem("menuId", 2);
                this.$store.dispatch("setmenuid", 2);
                if (item.is_join == 1) {
                    //已经加入课程
                    this.$router.push({
                        path: "/home/wisdompracticeteaching/courselearning?course_sn=" + item.sn,
                    });
                } else {
                    this.$router.push({
                        path: "/home/wisdompracticeteaching/coursedetails?course_sn=" + item.sn,
                    });
                }
            } else if (el.type == 3) {
                this.$router.push({
                    path: "/home/livebroadcast/courselearning?sn=" + item.sn,
                });
                document.title = item.title
            }

        },
    },
};
</script>
<style scoped lang="scss">
.search_cont {
    // border: 1px solid red;
    // box-sizing: border-box;
    width: 1200px;
    min-height: 300px;
    margin: 20px auto 0px;

    .course_cont {
        min-height: 300px;
        font-family: PingFang SC;

        .tags_cont {
            position: relative;
            height: 48px;
        }

        .tags {
            height: 30px;
            margin: 0px auto;
            padding: 0px 16px;
            background: #E6F1FF;
            border-radius: 15px;
            font-size: 14px;
            font-weight: 400;
            color: #666;
            text-align: center;
            line-height: 30px;
            display: inline-block;
            position: absolute;
            left: 50%;
            transform: translateX(-25%);

            .key_text {
                color: #0773FC;
            }

            .isMargin {
                margin: 0px 6px;
            }
        }



        .result {
            margin-top: 10px;

            .share,
            .private {
                padding-bottom: 60px;
                position: relative;
            }

            .more_cont {
                font-size: 14px;
                font-weight: 400;
                color: #0773FC;
                text-align: center;
                position: absolute;
                bottom: 22px;
                left: 50%;
                transform: translateX(-25%);
                cursor: pointer;
            }

            .more_cont1 {
                display: none;
            }

            .title {
                font-size: 24px;
                font-weight: 500;
                color: #000000;
                margin-bottom: 26px;
            }

            .moreShareHeight,
            .morePrivateHeight {
                min-height: 990px;
                // overflow: hidden;
            }

            .container {
                cursor: pointer;

                .item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;

                    .item_img {
                        width: 280px;
                        height: 169px;
                        border-radius: 5px;
                        overflow: hidden;

                        .el-image {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .item_detail {
                        width: calc(100% - 280px);
                        height: 169px;
                        padding-left: 26px;
                        display: flex;
                        flex-wrap: wrap;
                        align-content: space-between;

                        .item_info {
                            width: 100%;
                            cursor: pointer;

                            .name {
                                font-size: 16px;
                                font-weight: bold;
                                color: #000000;
                                margin-bottom: 15px;
                            }

                            .detail {
                                font-size: 14px;
                                font-weight: 400;
                                color: #666666;

                            }

                            .info {
                                font-weight: 500;
                                margin: 12px 0px;
                                height: 15px;
                                line-height: 15px;
                                margin-bottom: 15px;
                            }

                            .brief {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                width: 100%;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 3;
                                word-break: break-all;
                                margin-bottom: 15px;
                                line-height: 25px;
                                text-align: justify;
                            }
                        }

                        .item_link {
                            width: 100%;
                            font-size: 14px;
                            font-weight: 500;
                            color: #999999;

                            .time {
                                margin-right: 50px;
                            }

                            .link {
                                a {
                                    margin-left: 16px;
                                }
                            }

                            i {
                                font-size: 22px;
                                padding-right: 10px;
                                transform: translateY(3px);
                            }
                        }
                    }
                }

                .item:not(:first-child) {
                    margin-top: 35px;
                }
            }
        }


    }
}
</style>
  